import { TableRow } from "@mui/material";
import moment from "moment";

import { projectColDef, scheduleColDef } from "./Columns";
import ProjectCell from "./ProjectCell";
import GanttCell from "./GanttCell";
import { ScheduleCell } from "./ScheduleCell";

import { useGantt } from "contexts/gantt";
import { useAlerts } from "contexts/alerts";
import { Schedule } from "API";

function DummyRow({ project }: any) {
  const { dates } = useGantt();
  const { addAlert } = useAlerts();
  const focus = () =>
    addAlert({
      severity: "warning",
      message: `「${project.name}」はスケジュールが設定されていません`,
    });
  return (
    <TableRow>
      {dates.map((date) => (
        <GanttCell
          key={`${project.id}__${date}`}
          row={null}
          date={date}
          focus={focus}
          noScheduleClassName="bg-stone-50 hover:cursor-not-allowed"
        />
      ))}
    </TableRow>
  );
}

function ScheduleRow({ schedule }: any) {
  const { dates, focus, column } = useGantt();
  const scheduleCols = scheduleColDef();
  const draggableId = schedule.id;

  return (
    <TableRow key={schedule.id}>
      {scheduleCols
        .filter((col: { field: string }) => column.includes(col.field))
        .map((col) => (
          <ScheduleCell
            key={`${schedule.id}__${col.field}`}
            schedule={schedule}
            col={col}
          />
        ))}
      {dates.map((date) => (
        <GanttCell
          key={`${schedule.id}__${date}`}
          row={schedule}
          date={date}
          focus={() => focus(schedule)}
          draggableId={draggableId}
        />
      ))}
    </TableRow>
  );
}

export default function ProjectRow({ project }: any) {
  const projectCols = projectColDef();
  const { column } = useGantt();

  const scheduleExists = project.schedules?.items.length > 0;

  // projectにschedule更新情報を付与
  project.recentlyScheduleUpdated = recentlyScheduleUpdated(
    project.schedules?.items,
    3
  );

  return (
    <>
      <TableRow>
        {projectCols
          .filter((col) => column.includes(col.field))
          .map((col) => (
            <ProjectCell
              key={`${project.id}__${col.field}`}
              row={project}
              col={col}
            />
          ))}
      </TableRow>
      {!scheduleExists && <DummyRow project={project} />}
      {project.schedules?.items.map((schedule: any) => (
        <ScheduleRow key={schedule.id} schedule={schedule} />
      ))}
    </>
  );
}

// scheduleのリストと日数を渡して、直近N日以内に更新されたscheduleの中で最新のupdatedAtを返す
// 該当するものがない場合は、空文字を返す
const recentlyScheduleUpdated = (
  schedules: Schedule[],
  numOfDays: number
): string => {
  // 直近N日以内に更新されたscheduleを抽出
  const scheduleUpdated = schedules.filter((schedule: Schedule) => {
    // updatedAtとcreatedAtが同じ場合は更新されてない
    if (schedule.createdAt === schedule.updatedAt) {
      return false;
    }
    // updatedAtが現在時刻の過去3日以内だったら更新されている、momentを使って描く
    return moment(schedule.updatedAt).isAfter(
      moment().subtract(numOfDays, "days")
    );
  });

  // 直近N日以内に更新されたscheduleがない場合は、空文字を返す
  if (scheduleUpdated.length === 0) {
    return "";
  }

  // 直近N日以内に更新されたscheduleがある場合は、その中で最もupdatedAtが新しいものを抽出
  const latestScheduleUpdated = scheduleUpdated?.reduce(
    (a: Schedule, b: Schedule) =>
      moment(a.updatedAt).isAfter(moment(b.updatedAt)) ? a : b
  );
  return latestScheduleUpdated.updatedAt;
};
