import React, { useState } from "react";
import { Button, Menu, FormControlLabel, Switch } from "@mui/material";

import { projectColDef, scheduleColDef } from "../Table/Columns";

import { useGantt } from "contexts/gantt";

// 制御する項目(Table/ColumnsからshowColumnsMenu=trueのものを取得)
export const defColumns = [
  ...projectColDef().filter((col) => col.showColumnsMenu),
  ...scheduleColDef().filter((col) => col.showColumnsMenu),
];

export default function Column() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { column, setColumn } = useGantt();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="relative">
      <Button
        variant="outlined"
        onClick={handleClick}
        className="whitespace-nowrap"
      >
        表示項目
      </Button>
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {defColumns.map((defColumn) => (
          <div key={defColumn.field} className="hover:bg-gray-100">
            <FormControlLabel
              label={defColumn.headerName}
              className="block px-2"
              control={
                <Switch
                  checked={column.includes(defColumn.field)}
                  onChange={() =>
                    column.includes(defColumn.field)
                      ? setColumn(column.filter((f) => f !== defColumn.field))
                      : setColumn(column.concat(defColumn.field))
                  }
                />
              }
            />
          </div>
        ))}
      </Menu>
    </div>
  );
}
