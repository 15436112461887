import { useState } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

import { NewProject } from "./NewProject";
import ProjectFilter from "./ListView/Filter";
import GanttHeader from "./ScheduleView/Header";
import ListView from "./ListView";
import ScheduleView from "./ScheduleView";
import Menu from "./Menu";
import SaveFilterButton from "./SaveFilterButton";

import { useProjects } from "contexts/projects";
import { useGroupReports } from "contexts/groupReports";
import { useAuth } from "contexts/auth";

function ViewSelect({ view, setView }: { view: string; setView: any }) {
  return (
    <Select
      variant="outlined"
      size="small"
      value={view}
      renderValue={(value) =>
        value === "schedule" ? (
          <Tooltip title="スケジュール">
            <AccountTreeIcon />
          </Tooltip>
        ) : (
          <Tooltip title="リスト">
            <FormatListBulletedIcon />
          </Tooltip>
        )
      }
      className="bg-white"
      onChange={(e) => setView(e.target.value)}
    >
      <MenuItem value="schedule">
        <AccountTreeIcon sx={{ mr: 1 }} fontSize="small" />
        スケジュール
      </MenuItem>
      <MenuItem value="list">
        <FormatListBulletedIcon sx={{ mr: 1 }} fontSize="small" />
        リスト
      </MenuItem>
    </Select>
  );
}

function Projects() {
  const [view, setView] = useState<"list" | "schedule">("schedule");
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const { projects, creating } = useProjects();
  const { planReports, woodReports, invoiceReports } = useGroupReports();
  const { permissions } = useAuth();

  return (
    <>
      {creating && <LinearProgress />}
      <NewProject
        open={openCreate}
        setOpen={setOpenCreate}
        projects={projects}
      />
      <div className="m-4 flex justify-between">
        <div className="lg:flex items-center gap-4">
          <div className="flex items-center gap-4 mb-4 lg:mb-0">
            <Typography variant="h6" className="whitespace-nowrap">
              案件一覧
            </Typography>
            <SaveFilterButton />
            <ViewSelect view={view} setView={setView} />
            <ProjectFilter />
          </div>
          <div className="flex items-center gap-4">
            {view === "schedule" && <GanttHeader />}
          </div>
        </div>
        <div className="flex item-center">
          {planReports.length + woodReports.length + invoiceReports.length >
            0 && <Menu />}
          {permissions.Project.canWrite && (
            <Button
              onClick={() => setOpenCreate(true)}
              variant="outlined"
              sx={{ maxHeight: "40px" }}
            >
              新規
            </Button>
          )}
        </div>
      </div>
      <Divider />
      {view === "schedule" && <ScheduleView />}
      {view === "list" && <ListView />}
    </>
  );
}

export default Projects;
