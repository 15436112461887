import Button from "@mui/material/Button";
import { useState, MouseEvent } from "react";
import PrintIcon from "@mui/icons-material/Print";

import Menu from "components/Menu";
import { useGroupReports } from "contexts/groupReports";
import { useAuth } from "contexts/auth";

export default function ProjectsMenu() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const { planReports, woodReports, invoiceReports, download } =
    useGroupReports();
  const { permissions } = useAuth();

  if (!planReports) return <div />;

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setOpenMenu(true);
    setAnchor(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    setAnchor(null);
  };

  const menus: any = [
    ...planReports.map((report) => ({
      label: `${report.name}を印刷`,
      onClick: async () => {
        await download(report);
      },
      icon: <PrintIcon />,
    })),
    ...woodReports.map((report) => ({
      label: `${report.name}を印刷`,
      onClick: async () => {
        await download(report);
      },
      icon: <PrintIcon />,
    })),
    ...invoiceReports.map((report) => ({
      label: `${report.name}を印刷`,
      onClick: async () => {
        await download(report);
      },
      icon: <PrintIcon />,
    })),
  ];

  if (!permissions.GroupReport.canRead) return null;

  return (
    <div className="mr-3">
      <Button onClick={handleClick}>
        <PrintIcon />
      </Button>
      <Menu
        open={openMenu}
        anchor={anchor}
        menus={menus}
        onClose={handleCloseMenu}
      />
    </div>
  );
}
