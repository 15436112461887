import { useState } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import Button from "@mui/material/Button";
import moment from "moment";
import ja from "date-fns/locale/ja";

import { useGantt } from "contexts/gantt";
import { useProjects } from "contexts/projects";
import { removeBeforeIdx } from "utils/gantt";

const displayFormat = "MM月DD日";

const definedRanges = [
  {
    label: "今週",
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  },
  {
    label: "先週",
    startDate: moment().startOf("week").subtract(1, "week").toDate(),
    endDate: moment().endOf("week").subtract(1, "week").toDate(),
  },
  {
    label: "来週",
    startDate: moment().startOf("week").add(1, "week").toDate(),
    endDate: moment().endOf("week").add(1, "week").toDate(),
  },
  {
    label: "今月",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  },
  {
    label: "先月",
    startDate: moment().subtract(1, "month").startOf("month").toDate(),
    endDate: moment().subtract(1, "month").endOf("month").toDate(),
  },
  {
    label: "来月",
    startDate: moment().add(1, "month").startOf("month").toDate(),
    endDate: moment().add(1, "month").endOf("month").toDate(),
  },
  {
    label: "+7Days",
    startDate: moment().toDate(),
    endDate: moment().add(7, "day").toDate(),
  },
  {
    label: "+30Days",
    startDate: moment().toDate(),
    endDate: moment().add(1, "month").toDate(),
  },
];

export default function DateRange() {
  const [open, setOpen] = useState(false);
  const { dateRange, setDateRange, dateLabel, setDateLabel } = useGantt();

  const { projects } = useProjects();

  const { startDate, endDate } = dateRange;
  const displayDateRange = `${moment(startDate).format(
    displayFormat
  )} - ${moment(endDate).format(displayFormat)}`;

  const toggle = () => setOpen(!open);

  //PAX-607 dateLabelを取得してコンテキストに渡す
  const handleDateRangeChange = (range: any) => {
    const selectedIndex = definedRanges.findIndex(
      (item) =>
        item.startDate.getTime() === range.startDate.getTime() &&
        item.endDate.getTime() === range.endDate.getTime()
    );
    if (selectedIndex !== -1) {
      setDateLabel(definedRanges[selectedIndex].label);
    } else {
      setDateLabel(""); // ラベルがない場合は空の文字列に設定
    }

    setDateRange(range);
    removeBeforeIdx(projects);
  };

  return (
    <div className="relative">
      <Button variant="outlined" onClick={toggle} className="whitespace-nowrap">
        {displayDateRange}
      </Button>
      <div className="absolute z-[100]">
        <DateRangePicker
          key={dateRange.startDate?.toISOString()} //DateRangeが変わる時に、再レンダリングするためのキー
          open={open}
          toggle={toggle}
          onChange={handleDateRangeChange}
          initialDateRange={dateRange}
          locale={ja}
          definedRanges={definedRanges}
        />
      </div>
    </div>
  );
}
