import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ProjectRow from "./ProjectRow";
import { projectColDef, scheduleColDef, dateColDef } from "./Columns";

import { useGantt } from "contexts/gantt";
import { useProjects } from "contexts/projects";
import { useGroups } from "contexts/groups";
import { useAuth } from "contexts/auth";

function ScheduleHeaders() {
  const { dates, column } = useGantt();
  const { groups } = useGroups();
  const { currentGroup } = useAuth();
  const projectCols = projectColDef().filter((col) =>
    column.includes(col.field)
  );
  const scheduleCols = scheduleColDef().filter((col) =>
    column.includes(col.field)
  );
  const holidayCalendar = groups?.find((group) => group.id === currentGroup?.id)
    ?.holidays?.items;

  const dateCols = dateColDef(dates, holidayCalendar);

  const columns = [...projectCols, ...scheduleCols, ...dateCols];

  return (
    <TableHead>
      <TableRow>
        {columns.map((col: any) => (
          <TableCell key={col.field} className={col.headerClassName}>
            {col.headerName}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function ScheduleBody() {
  const { projects } = useGantt();

  return (
    <DndProvider backend={HTML5Backend}>
      <TableBody>
        {projects.map((project: any) => (
          <ProjectRow key={project.id} project={project} />
        ))}
      </TableBody>
    </DndProvider>
  );
}

function ScheduleTable() {
  const { loading } = useProjects();
  if (loading) return <LinearProgress color="secondary" />;
  return (
    <TableContainer component={Paper} sx={{ height: "85vh" }}>
      <Table
        size="small"
        stickyHeader
        sx={{
          tableLayout: "fixed",
          overflow: "scroll",
        }}
      >
        <ScheduleHeaders />
        <ScheduleBody />
      </Table>
    </TableContainer>
  );
}

export default ScheduleTable;
