import PushPinIcon from "@mui/icons-material/PushPin";
import ToggleButton from "@mui/material/ToggleButton";

import { useProjects } from "contexts/projects";
import { useGantt } from "contexts/gantt";

export default function SaveFilterButton() {
  const { isFilterSaved, setIsFilterSaved } = useGantt();
  const { isProjectsFilterSaved, setIsProjectsFilterSaved } = useProjects();

  return (
    <ToggleButton
      color="secondary"
      value="check"
      selected={isFilterSaved}
      onChange={() => {
        setIsFilterSaved(!isFilterSaved);
        setIsProjectsFilterSaved(!isProjectsFilterSaved);
      }}
    >
      <PushPinIcon />
    </ToggleButton>
  );
}
