import DateRange from "./DateRange";
import Sort from "./Sort";
import Filter from "./Filter";
import Column from "./Column";

export default function GanttHeader() {
  return (
    <>
      <DateRange />
      <Filter />
      <Sort />
      <Column />
    </>
  );
}
