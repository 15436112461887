import { TableCell, TextField } from "@mui/material";
import { useState } from "react";

import { useProjects } from "contexts/projects";
import { updateScheduleByInput } from "utils/gantt/schedule";
import { Schedule, UpdateScheduleInput } from "API";
import { useAuth } from "contexts/auth";

export function ScheduleCell({ schedule, col }: any) {
  const { projects, set: setProject } = useProjects();
  const { permissions } = useAuth();
  const [editColumns, setEditColumns] = useState<string[]>([]);

  const scheduleId = schedule["id"];
  // リストをダブルクリックしたときの操作
  const changeEditStatus = (id: string) => {
    // 編集中のカラムを記録する。ひとつしかないとは思われるが複数対応。
    if (permissions.Project.canWrite) {
      editColumns?.includes(id)
        ? setEditColumns(editColumns.filter((col) => col !== id))
        : setEditColumns(editColumns.concat(id));
    }
  };
  // 値が含まれるか確認する
  const isEditable = (id: string, editable: boolean) => {
    return editColumns.includes(id) && editable;
  };
  // スケジュールのはじめはs.なので排除する
  const replaceField = (field: string) => {
    return field.replace("s.", "");
  };
  // スケジュールデータ更新
  const handleUpdate = (field: string, value: any) => {
    // 編集状態を解除する
    changeEditStatus(scheduleId);
    // 編集前と変わらない場合は処理中断
    if (schedule[field] === value) return;
    // 値の変更
    schedule[field] = value;
    // スケジュール更新を準備
    const inputSchedule: UpdateScheduleInput = {
      id: scheduleId,
      [field]: value,
    };
    // スケジュールの更新
    updateScheduleByInput(inputSchedule);
    // 案件を更新
    const targetProject = projects.find(
      (project) => project.id === (schedule["projectId"] as string)
    );
    if (targetProject) {
      const targetSchedule: any = targetProject.schedules?.items.find(
        (findSchedule: Schedule | null) => findSchedule?.id === scheduleId
      );
      if (targetSchedule && Object.keys(targetSchedule).includes(field)) {
        // 対象フィールドに値を設定
        targetSchedule[field] = value;
      }
      setProject(targetProject);
    }
  };

  return (
    <TableCell
      key={`${schedule.id}__${col.field}`}
      className={col.cellClassName}
      onDoubleClick={(event) => {
        changeEditStatus(scheduleId);
      }}
    >
      {isEditable(scheduleId, col.editable) && (
        <TextField
          size="small"
          defaultValue={schedule[replaceField(col.field)]}
          onBlur={(event) => {
            handleUpdate(replaceField(col.field), event.target.value);
          }}
          inputProps={col.inputProps}
          autoFocus
        />
      )}
      {!isEditable(scheduleId, col.editable) &&
        schedule[replaceField(col.field)]}
    </TableCell>
  );
}
