import type { NextPage } from "next";
import Head from "next/head";

import { HomeLayout } from "layouts";
import { ProjectsProvider } from "contexts/projects";
import { AccountsProvider } from "contexts/accounts";
import Projects from "components/Projects";
import { TenantProvider } from "contexts/tenant";
import { ContactsProvider } from "contexts/contacts";
import { GanttProvider } from "contexts/gantt";
import { GroupReportsProvider } from "contexts/groupReports";
import { useAuth } from "contexts/auth";
import AccessGate from "components/AccessGate";
import { UsersProvider } from "contexts/users";
import { useUsers } from "contexts/users";
import LicenseNotValid from "components/LicenseNotValid";
import { LicenseEnum } from "utils/license";
import { GroupsProvider } from "contexts/groups";

const Home: NextPage = () => {
  const { permissions } = useAuth();
  const { isLicenseValidForMe, myLicense } = useUsers();

  if (!isLicenseValidForMe && !permissions.TenantMember.canWrite) {
    return <LicenseNotValid />;
  }
  if (!isLicenseValidForMe && myLicense === LicenseEnum.SUPPORT) {
    return <LicenseNotValid />;
  }
  if (!isLicenseValidForMe && permissions.TenantMember.canWrite) {
    return (
      <HomeLayout>
        <LicenseNotValid />
      </HomeLayout>
    );
  }

  return (
    <div>
      <Head>
        <title>PAX</title>
        <meta name="description" content="Pax mobile" />
        <link rel="manifest" href="/manifest.json" />
      </Head>

      <main>
        <HomeLayout>
          <AccessGate
            permission={permissions.Project}
            message="プロジェクトを閲覧する権限がありません"
          >
            <AccountsProvider>
              <ContactsProvider by="AccountId">
                <ProjectsProvider isForManager>
                  <GanttProvider>
                    <GroupReportsProvider>
                      <GroupsProvider>
                        <Projects />
                      </GroupsProvider>
                    </GroupReportsProvider>
                  </GanttProvider>
                </ProjectsProvider>
              </ContactsProvider>
            </AccountsProvider>
          </AccessGate>
        </HomeLayout>
      </main>
    </div>
  );
};

function Root() {
  return (
    <TenantProvider>
      <UsersProvider>
        <Home />
      </UsersProvider>
    </TenantProvider>
  );
}

export default Root;
