// QuoteLines
import { useState } from "react";
import {
  DataGrid,
  GridSelectionModel,
  GridToolbar,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";

import columns from "./Columns";

import { Project } from "API";
import { useProjects } from "contexts/projects";
import { dateStringToJaDateStr } from "utils/date";
import { dataGridToolbar } from "utils/toolbar";
import { useAuth } from "contexts/auth";

const dataToRows = (data: Project[]) => {
  if (!data) return [];
  let rows = data.map((d) => ({
    id: d.id,
    groupId: d.groupId,
    quoteId: d.quoteId,
    userId: d.userId,
    managerId: d.managerId,
    managerName: d.managerName,
    quoterId: d.quoterId,
    quoterName: d.quoterName,
    operatorId: d.operatorId,
    operatorName: d.operatorName,
    accountId: d.accountId,
    accountName: d.accountName,
    contactId: d.contactId,
    contactName: d.contactName,
    name: d.name,
    status: d.status,
    inquiryDate: d.inquiryDate,
    quotationDate: d.quotationDate,
    destinationName: d.destinationName,
    destination: d.destination,
    confidence: d.confidence,
    description: d.description,
    alert: d.alert,
    cancelled: d.cancelled,
    cancelReason: d.cancelReason,
    caseMarkNumImgs: d.caseMarkNumImgs,
    stockNumImgs: d.stockNumImgs,
    reference: d.reference,
    photoPhases: d.photoPhases,
    type: d.type,
    archived: d.archived,
    createdAt: d.createdAt ? dateStringToJaDateStr(d.createdAt) : null,
    updatedAt: d.updatedAt ? dateStringToJaDateStr(d.updatedAt) : null,
    account: d.account,
    contact: d.contact,
    schedules: d.schedules,
    user: d.user,
    group: d.group,
  }));
  return rows;
};

interface TableProps {
  setSelected: (s: GridSelectionModel) => void;
}

export function Table({ setSelected }: TableProps) {
  const { projects, loading } = useProjects();
  const [open, setOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<Project>();
  const { permissions } = useAuth();
  //PAX-538 デフォルトで表示しない列はここで指定する
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      managerName: false,
      quoterName: false,
      operatorName: false,
      user: false,
      alert: false,
      caseMarkNumImgs: false,
      stockNumImgs: false,
      archived: false,
      cancelled: false,
      cancelReason: false,
    });

  if (!projects && !loading) return null;

  const handleEdit = (id: string) => {
    const project = projects.filter((a) => a.id === id)[0];
    setDefaultValues(project);
    setOpen(true);
  };

  return (
    <div className="flex h-full">
      <div className="grow">
        <DataGrid
          className="bg-white"
          components={{ Toolbar: dataGridToolbar }}
          loading={loading}
          autoHeight
          rows={dataToRows(projects)}
          columns={columns(handleEdit, permissions.MasterData)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          initialState={{
            sorting: {
              sortModel: [{ field: "reference", sort: "desc" }],
            },
          }}
          density="compact"
          // PAX-538 列選択をできないようにしている(1列だけ掴むとCSV出力などがうまくいかないため)
          isRowSelectable={(params) => false}
        />
      </div>
    </div>
  );
}

export default Table;
