import TableCell from "@mui/material/TableCell";

// スケジュールのカラムに表示する内容をカスタマイズする
const renderCellValue = (row: any, col: any) => {
  switch (col.field) {
    case "accountName": // 取引先
      return `${row.account?.name ?? ""}`;
    case "userName": // 作成者
      return `${row.user?.lastName ?? ""} ${row.user?.firstName ?? ""}`;
    default:
      return row[col.field];
  }
};

export default function ProjectCell({ row, col }: any) {
  let rowSpan = row.schedules?.items.length + 1;
  if (rowSpan < 2) rowSpan = 2;
  return (
    <TableCell sx={{ minWidth: 10 }} rowSpan={rowSpan}>
      <div className={col.cellClassName}>
        {col.renderCell
          ? col.renderCell({
              id: row.id,
              value: renderCellValue(row, col),
            })
          : row[col.field]}
      </div>
    </TableCell>
  );
}
