import TextField from "@mui/material/TextField";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";

import { useGantt } from "contexts/gantt";
import { ProjectDateSortName, removeBeforeIdx } from "utils/gantt";
import { useProjects } from "contexts/projects";

const ascOptions = [
  { value: "sortByStatus", label: "フェーズ順" },
  { value: "sortByName", label: "案件名順" },
  { value: "sortByStock", label: "入荷日順" },
  { value: "sortByPackage", label: "梱包日順" },
  { value: "sortByShip", label: "出荷日順" },
  { value: "sortByCut", label: "CUT日順" },
  { value: "sortByCreate", label: "作成日順" },
  { value: "sortByUpdate", label: "最終更新日順" },
];

const descOptions = [
  { value: "sortByStatusDesc", label: "フェーズ順" },
  { value: "sortByNameDesc", label: "案件名順" },
  { value: "sortByStockDesc", label: "入荷日順" },
  { value: "sortByPackageDesc", label: "梱包日順" },
  { value: "sortByShipDesc", label: "出荷日順" },
  { value: "sortByCutDesc", label: "CUT日順" },
  { value: "sortByCreateDesc", label: "作成日順" },
  { value: "sortByUpdateDesc", label: "最終更新日順" },
];

export default function ProjectFilter() {
  const { sort, setSort } = useGantt();
  const { projects } = useProjects();

  return (
    <TextField
      select
      label="ソート"
      value={sort}
      onChange={(e) => {
        setSort(e.target.value as ProjectDateSortName);
        removeBeforeIdx(projects);
      }}
      sx={{ minWidth: 160 }}
      size="small"
    >
      <ListSubheader>昇順</ListSubheader>
      {ascOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label} <ArrowDownIcon />
        </MenuItem>
      ))}
      <ListSubheader>降順</ListSubheader>
      {descOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label} <ArrowUpwardIcon />
        </MenuItem>
      ))}
    </TextField>
  );
}
