import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useAuth } from "./auth";

import type { Group } from "API";
import useDataList, { GraphQLInput } from "hooks/datalist";

interface GroupsContextValue {
  loading: boolean;
  groups: Group[] | undefined;
  update: (input: GraphQLInput) => void;
  create: (input: GraphQLInput) => void;
  refetch: () => Promise<void>;
  set: (input: GraphQLInput) => void;
}

interface GroupsContextProps {
  caseOrder?: string | null | undefined;
  children: ReactNode;
}

const GroupsContext = createContext<GroupsContextValue>({
  groups: [],
  loading: false,
  update: () => null,
  create: () => null,
  refetch: () => Promise.resolve(),
  set: () => Promise.resolve,
});

export const GroupsProvider = ({ children }: GroupsContextProps) => {
  const { user } = useAuth();
  const { data, loading, refetch, update, create, set } = useDataList({
    query: "groupsByTenantId",
    variables: {
      tenantId: user?.tenantId,
    },
  });

  useEffect(() => {
    refetch({
      tenantId: user?.tenantId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.tenantId]);

  const createGroup = async (input: GraphQLInput) => {
    await create("createGroup", {
      ...input,
      tenantId: user?.tenantId,
    });
  };

  const updateGroup = async (input: GraphQLInput) => {
    await update("updateGroup", {
      ...input,
    });
  };

  const values = useMemo(
    () => ({
      groups: data,
      update: updateGroup,
      create: createGroup,
      loading,
      refetch: () => refetch({ tenantId: user?.tenantId }),
      set,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, update, loading, create, set]
  );

  return (
    <GroupsContext.Provider value={values}>{children}</GroupsContext.Provider>
  );
};

export const useGroups = () => {
  const groupsContext = useContext(GroupsContext);

  if (groupsContext === undefined) {
    throw new Error("useGroups must be within GroupsProvider");
  }

  return groupsContext;
};
