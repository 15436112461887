import { useState } from "react";
import { GridSelectionModel } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import Table from "./Table";

import { getStatusInitial, getStatusColor } from "utils/status";
import { useProjects } from "contexts/projects";
import { useAuth } from "contexts/auth";
import type { Project } from "API";

function ProjectListView() {
  const [open, setOpen] = useState<boolean>(false);
  const { projects, loading, remove } = useProjects();
  const [selected, setSelected] = useState<GridSelectionModel>([]);
  const { permissions } = useAuth();

  if (loading) return <LinearProgress />;

  if (!projects && !loading) return null;

  return (
    <div className="w-full p-2">
      <div className="flex justify-between"></div>
      <Table setSelected={setSelected} />
    </div>
  );
}

export default ProjectListView;
