import moment from "moment";
import { useEffect } from "react";
import { setCookie } from "nookies";

import GanttTable from "./Table";

import { useGantt } from "contexts/gantt";
import { defaultCookieOptions, parseCookieBooleanValue } from "utils/cookie";

//PAX-607 日付変更時再設定
const resetDate = (
  dateRange: { startDate: Date; endDate: Date },
  setDateRange: (range: { startDate: Date; endDate: Date }) => void,
  dateLabel: string
) => {
  const newDate = moment();
  const { startDate, endDate } = dateRange;

  if (
    !newDate.isSame(startDate, "day") &&
    (dateLabel == "+7Days" || dateLabel == "+30Days")
  ) {
    const daysToAdd = dateLabel === "+30Days" ? 30 : 7;
    setDateRange({
      startDate: moment().toDate(),
      endDate: moment().add(daysToAdd, "day").toDate(),
    });
    // Cookieの設定もここで行う
    setCookie(
      null,
      "ganttStartDate",
      startDate?.toString() ?? "",
      defaultCookieOptions
    );
    setCookie(
      null,
      "ganttEndDate",
      endDate?.toString() ?? "",
      defaultCookieOptions
    );
  }
};

function ScheduleView() {
  const { dateRange, setDateRange, dateLabel } = useGantt();

  //PAX-607 日付変更時再設定
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      resetDate(
        { startDate: dateRange.startDate!, endDate: dateRange.endDate! },
        setDateRange,
        dateLabel || "" // nullの場合は空文字列を使用
      );
    }
  }, [dateRange, setDateRange, dateLabel]); // 依存配列にdateRangeとdateLabelを追加

  return <GanttTable />;
}

export default ScheduleView;
